import { useEffect, useState } from 'react'

import { navigate, useLocation } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import CenteredLoading from 'src/components/Library/Loading/CenteredLoading'
import Loading from 'src/components/Library/Loading/Loading'

const BaseLinkPage = () => {
  const { pathname } = useLocation()
  const [isIframeLoading, setIsIframeLoading] = useState(true)

  useEffect(() => {
    let location = ''
    let linkType = ''

    if (pathname.includes('/app/baselink')) {
      location = pathname.split('/app/baselink')[1]
      if (!pathname.includes('/form') && !pathname.includes('/public/grid')) {
        linkType = 'baselink'
        navigate(`/app/bases?linkData=${location}&linkType=${linkType}`)
      }
    } else if (pathname.includes('/notification')) {
      location = pathname.split('/notification')[1]
      linkType = 'notification'
      navigate(`/app/bases?linkData=${location}&linkType=${linkType}`)
    } else {
      navigate('/app/bases')
    }
  }, [pathname])

  if (
    pathname.includes('/app/baselink/form') ||
    pathname.includes('/app/baselink/public/grid')
  ) {
    const location = pathname.split('/app/baselink')[1]
    return (
      <>
        <Metadata
          title="Workflows Public"
          description="Workflows Public page"
        />
        <div className="h-screen">
          {isIframeLoading && <CenteredLoading />}
          <iframe
            id={'baserow-frame'}
            title={'baserow-frame'}
            width={'100%'}
            height={'100%'}
            src={`${process.env.BASEROW_URL}${location}`}
            onLoad={() => {
              setIsIframeLoading(false)
            }}
            onError={() => {
              setIsIframeLoading(false)
            }}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Metadata title="Loading" description="Loading" />
      <Loading />
    </>
  )
}

export default BaseLinkPage
